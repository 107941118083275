@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/fonts/PlusJakartaSans-VariableFont_wght.ttf');
  font-display: block;
  font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    'Plus Jakarta Sans',
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.input__focus:focus {
  border: 1px solid var(--color-base-black) !important;
}

.divider__color::before {
  color: var(--color-text-bright-grey) !important;
}

.button__form-pink:hover {
  background-color: var(--color-background-ruby) !important;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

.button__form-pink:active {
  background-color: #ed458e !important;
}

.grecaptcha-badge {
  visibility: hidden;
}
